<template>
  <div>
    <Divider dashed style="margin: 5px 0;">
      <span class="divider-text">{{pageTitle}}</span>
    </Divider>
    <Form ref="fineformValidate" :model="fineBean" :rules="ruleValidate">
      <Row>
        <i-col span="24">
          <FormItem label="审批名称" prop="title">
            <Input v-model="fineBean.title" size="small" maxlength="50" placeholder="请输入审批内容的名称..." />
          </FormItem>
        </i-col>
        <i-col span="24">
          <FormItem label="罚款类型" prop="feeType">
            <RadioGroup size="small" v-model="fineBean.feeType" @on-change="handleChangeFeeType">
              <Radio
                v-for="feeType in feeTypeList"
                :key="feeType.id"
                :label="feeType.id"
              >{{feeType.name}}</Radio>
            </RadioGroup>
          </FormItem>
        </i-col>
      </Row>

      <Row>
        <i-col span="24">
          <FormItem label="代理商" prop="agentCompanyId">
            <Select v-model="fineBean.agentCompanyId" placeholder="选择所属代理商" size="small">
              <Option
                v-for="agent in agentCompanyList"
                :value="agent.id"
                :key="agent.id"
              >{{ agent.name }}</Option>
            </Select>
          </FormItem>
        </i-col>
        <i-col span="24" v-if="fineBean.feeType === 52">
          <FormItem label="供应商" prop="supplierCompanyId">
            <Select v-model="fineBean.supplierCompanyId" placeholder="选择所属供应商" size="small">
              <Option
                v-for="supplier in supplierCompanyList"
                :key="supplier.id"
                :value="supplier.id"
              >{{supplier.name}}</Option>
            </Select>
          </FormItem>
        </i-col>
      </Row>

      <Row>
        <i-col span="24">
          <FormItem label="罚款金额" prop="amount">
            <InputNumber :min="1" v-model="fineBean.amount" placeholder="请输入罚款金额..." size="small"></InputNumber>元
          </FormItem>
        </i-col>
        <i-col span="24">
          <FormItem label="罚款日期" prop="penaltyDate">
            <DatePicker type="date" placeholder="设置罚款日期" v-model="fineBean.penaltyDate" size="small"></DatePicker>
          </FormItem>
        </i-col>
      </Row>

      <FormItem label="上传内容描述" prop="content">
        <Input
          v-model="fineBean.content"
          type="textarea"
          maxlength="500"
          class="p-t-5"
          :autosize="{minRows: 4,maxRows: 6}"
          placeholder="请输入上传内容描述，不能少于10个字符"
        />
      </FormItem>

      <FormItem>
        <Upload multiple type="drag" :before-upload="handleBeforeUploadFile" action>
          <div style="padding: 20px 0">
            <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
            <p>点击或拖拽文件到这里进行上传</p>
          </div>
        </Upload>
        <Row v-show="fileList.length">
          <i-col span="14">
            <b>文件名称</b>
          </i-col>
          <i-col span="5">
            <b>文件大小</b>
          </i-col>
          <i-col span="5">
            <b>操作</b>
          </i-col>
        </Row>
        <hr  v-show="fileList.length" />
        <Row v-for="(file,fileIndex) in fileList" :key="fileIndex" v-show="fileList.length">
          <i-col span="14">{{file.name}}</i-col>
          <i-col span="5">{{(file.size / 1024).toFixed(2)}} KB</i-col>
          <i-col span="5">
            <a class="delete" @click="handleRemove(file)">删除</a>
          </i-col>
          <i-col span="24">
            <hr />
          </i-col>
        </Row>
      </FormItem>

      <Row v-if="detailId" :bordered="false" dis-hover>
        <i-col span="24">
          <h4 slot="title">历史上传文件清单</h4>
          <i-table stripe  :columns="approvalFileColumn" :data="approvalFile" size="small"></i-table>
        </i-col>
      </Row>

      <FormItem class="text-right">
        <!-- <Button @click="handleSubmit(0)">保存草稿</Button> -->
        <Button type="success" class="m-l-10" size="small" @click="handleSubmit(4)">提交审批</Button>
      </FormItem>
    </Form>

    <Modal
      v-model="beginUpload"
      width="400"
      :styles="{top: '250px'}"
      :mask-closable="false"
      :closable="false"
      footer-hide
    >
      <h3 class="text-center">上传文件中..</h3>
      <Progress :percent="percentage" :stroke-width="20" status="active" text-inside></Progress>
    </Modal>
  </div>
</template>

<script>
import axios from 'axios'
import { getStorage } from '@/utils/storage'
import { ParseDate } from '@/utils/dateFormat'
import { downloadFileRequest } from '@/utils/download'

import { getCompanyList } from '@/api/os/company'
import { getPenalty } from '@/api/statement/penalty'

export default {
  data () {
    return {
      pageTitle: '创建罚单',
      feeTypeList: [
        { id: 52, name: '供应商罚单' },
        { id: 53, name: '代理商罚单' }
      ],
      agentCompanyList: [],
      supplierCompanyList: [],
      fineBean: {
        id: -1,
        title: '',
        feeType: 52,
        amount: 0,
        content: '',
        penaltyDate: '',
        agentCompanyId: '',
        supplierCompanyId: ''
      },
      ruleValidate: {
        title: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        agentCompanyId: [
          {
            required: true,
            type: 'number',
            message: ' ',
            trigger: 'change'
          }
        ],
        supplierCompanyId: [
          {
            required: true,
            type: 'number',
            message: ' ',
            trigger: 'change'
          }
        ],
        amount: [
          {
            type: 'number',
            min: 1,
            message: ' ',
            trigger: 'blur'
          }
        ],
        penaltyDate: [
          {
            required: true,
            type: 'date',
            message: ' ',
            trigger: 'change'
          }
        ],

        content: [
          { required: true, message: ' ', trigger: 'blur' },
          {
            type: 'string',
            min: 10,
            message: ' ',
            trigger: 'blur'
          }
        ]
      },
      fileList: [],
      percentage: 0, // 文件上传进度
      beginUpload: false, // 开始执行上传
      approvalFile: [],
      approvalFileColumn: [
        {
          title: '文件缩略图',
          render: (h, data) => {
            if (data.row.mimeType.startsWith('image')) {
              return h('Icon', {
                props: { type: 'md-images', size: 28, color: '#e96900' },
                style: { 'margin-left': '8px' }
              })
            } else if (this.isMSWord(data.row)) {
              return h('img', {
                attrs: {
                  src: require('@/assets/images/WinWordLogoSmall.scale-180.png'),
                  height: '30px'
                }
              })
            } else if (this.isMSExcel(data.row)) {
              return h('img', {
                attrs: {
                  src: require('@/assets/images/ExcelLogoSmall.scale-180.png'),
                  height: '30px'
                }
              })
            }
          }
        },
        { title: '文件名称', key: 'fileName' },
        { title: '文件大小 (kb)', key: 'length' },
        {
          title: '操作',
          render: (h, params) => {
            return h('div', [
              h(
                'a',
                {
                  style: { marginRight: '10px' },
                  on: {
                    click: () => {
                      this.downloadFile(params.row)
                    }
                  }
                },
                '下载'
              ),
              h(
                'a',
                {
                  on: {
                    click: () => {
                      this.deleteFile(params.row)
                    }
                  }
                },
                '移除'
              )
            ])
          }
        }
      ],
      deleteFileIds: []
    }
  },
  created () {
    this.initPageData()
  },
  methods: {
    initPageData () {
      const postData = {
        enabled: true,
        publisherId: this.$store.getters.token.userInfo.publisherId
      }

      getCompanyList(postData).then((res) => {
        this.agentCompanyList = res.filter((x) => x.category.includes(2))
        this.supplierCompanyList = res.filter((x) => x.category.includes(3))
      })

      // 验证是否为编辑界面
      if (this.detailId) {
        this.pageTitle = '编辑罚单'

        const query = { penaltyId: this.detailId }
        getPenalty(query).then((res) => {
          this.approvalFile = res.ossfileList
          this.fineBean.id = res.id
          this.fineBean.title = res.title
          this.fineBean.feeType = res.feeType
          this.fineBean.amount = res.amount
          this.fineBean.content = res.content
          this.fineBean.penaltyDate = res.penaltyDate
          this.fineBean.agentCompanyId = res.agentCompanyId
          this.fineBean.supplierCompanyId = res.supplierCompanyId
        })
      } else {
        this.pageTitle = '新建罚单'
        if (this.$refs.fineformValidate) {
          this.$refs.fineformValidate.resetFields()
        }
      }
    },
    handleBeforeUploadFile (file) {
      this.fileList.push(file)
      return false
    },
    handleRemove (file) {
      this.fileList.splice(this.fileList.indexOf(file), 1)
    },
    handleChangeFeeType () {
      this.fineBean.supplierCompanyId = ''
    },
    handleSubmit (status) {
      this.$refs.fineformValidate.validate((valid) => {
        if (valid) {
          this.beginUpload = true
          this.percentage = 0
          // 开始批量执行上传操作
          const that = this
          const uploadData = new FormData()
          uploadData.append('id', this.fineBean.id)
          uploadData.append('title', this.fineBean.title)
          uploadData.append('feeType', this.fineBean.feeType)
          uploadData.append('amount', this.fineBean.amount)
          uploadData.append('content', this.fineBean.content)
          uploadData.append(
            'penaltyDate',
            ParseDate(this.fineBean.penaltyDate)
          )
          uploadData.append('agentCompanyId', this.fineBean.agentCompanyId)
          uploadData.append(
            'supplierCompanyId',
            this.fineBean.supplierCompanyId
          )
          uploadData.append('status', status)
          uploadData.append(
            'publisherId',
            this.$store.getters.token.userInfo.publisherId
          )

          if (this.fineBean.id > -1) {
            // 执行编辑操作
            uploadData.append(
              'deletedFileIdList',
              JSON.stringify(this.deleteFileIds)
            )
          }

          this.fileList.forEach(function (file, index) {
            uploadData.append('files', file)
          })

          axios
            .post(
              process.env.VUE_APP_API_URL_V2 +
                '/ooh-statement/v1/penalty/createorupdatepenaltywithfiles',
              uploadData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  'ooh-auth': getStorage('ooh-token')
                },
                withCredentials: true,
                onUploadProgress (progress) {
                  // 处理上传文件进度条数据
                  that.percentage = Math.round(
                    (progress.loaded / progress.total) * 100
                  )
                }
              }
            )
            .then((res) => {
              this.beginUpload = false
              if (res && res.data && !res.data.errcode) {
                this.$Notice.success({ desc: '创建审批成功' })
                this.$refs.fineformValidate.resetFields()
                this.fileList = []
                this.$store.commit('set_approvalCreate_detailId', null)
                this.$store.commit('set_approvalCreate_update', new Date())
              } else {
                this.$Notice.error({ desc: res.data.errmsg })
              }
            })
        }
      })
    },
    deleteFile (file) {
      const fileIndex = this.approvalFile.findIndex((x) => x.id === file.id)
      this.approvalFile.splice(fileIndex, 1)
      this.deleteFileIds.push(file.id)
    },
    downloadFile (file) {
      downloadFileRequest(
        process.env.VUE_APP_API_URL_V2 +
          '/ooh-statement/v1/penalty/downloadpenaltyfile',
        { fileId: file.id },
        file.fileName
      )
    },
    isMSWord (file) {
      const mimeTypes = [
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/msword'
      ]
      return mimeTypes.includes(file.mimeType)
    },
    isMSExcel (file) {
      const mimeTypes = [
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ]
      return mimeTypes.includes(file.mimeType)
    }
  },
  computed: {
    detailId () {
      return this.$store.state.approvalCreate.detailId
    }
  },
  watch: {
    detailId (val) {
      this.initPageData()
    }
  }
}
</script>
